import './src/styles/global.css'

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Deze applicatie heeft een update beschikbaar.\n` +
     `Herladen om de nieuwste versie weer te geven?`
  );

  if(answer === true) {
    window.location.reload();
  }
}